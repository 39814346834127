'use client'
import '~/sass/layout/_header.scss';
import { usePathname } from 'next/navigation';

export default function UI() {
  const pathname = usePathname();
  return (
    <h1 style={{ margin: '0' }}>
      {pathname === '/checkout/purchase-limit' ? 'Información importante' : 'Proceso de Compra'}
    </h1>
  );
}
